<template>
  <div>
    <page-header :heading="pageHeading" :sub-heading="pageSubHeading" large-content>
    </page-header>
    <v-card class="mt-8">
      <div
        class="pa-4"
        v-html="pageContent"
      ></div>
    </v-card>
    <component-guard
      :roles="['admin', 'manager']"
    >
      <v-dialog
        v-model="editDialog"
        fullscreen
      >
        <template
          #activator="{ on, attrs }"
        >
          <div
            class="mt-8 d-flex justify-end"
          >
            <v-btn
              v-on="on"
              v-bind="attrs"
              color="green"
              class="white--text"
              v-text="editButtonText"
            ></v-btn>
          </div>
        </template>
        <v-card>
          <v-toolbar
            dark
            color="primary"
            class="rounded-0"
          >
            <v-btn
              icon
              dark
              @click="editDialog = false"
            >
              <v-icon>{{ $icons.mdiClose }}</v-icon>
            </v-btn>
            <v-toolbar-title
              v-text="editDialogTitle"
            ></v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pt-8">
            <v-container>
              <textarea
                v-model="markdown"
                style="visibility: hidden"
                id="mde"
              ></textarea>
              <div
                class="mt-8 d-flex justify-end"
              >
                <v-btn
                  color="green"
                  class="white--text"
                  @click="handleUpdate"
                  :loading="loading"
                >
                  {{ editDialogUpdateButton }}
                </v-btn>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </component-guard>

    <div v-if="version" class="version">{{ version }}</div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import ComponentGuard from '@/components/ComponentGuard'

import config from '@/config/config'
import i18n from '@/mixins/i18n.mixin'
import Page from '@/mixins/page.mixin'
import Markdown from '@/mixins/markdown.mixin'

import { mapActions, mapGetters } from 'vuex'

import EasyMDE from 'easymde'

export default {
  name: 'Help',
  mixins: [
    i18n,
    Page,
    Markdown
  ],
  components: {
    PageHeader,
    ComponentGuard
  },
  data () {
    return {
      editDialog: false,
      easyMde: null,
      markdown: '',
      markdownPageUuid: '00000000-0000-0000-0000-000000000000',
      version: config.get('version', {}, '')
    }
  },
  created () {
    this.getMarkdownPage(this.markdownPageUuid)
  },
  beforeDestroy () {
    this.setMarkdownPage(null)
  },
  methods: {
    ...mapActions('markdownPages', ['getMarkdownPage', 'setMarkdownPage', 'updateMarkdownPage']),
    handleUpdate () {
      this.updateMarkdownPage({
        markdownPageUuid: this.markdownPageUuid,
        data: {
          name: this.markdownPage.name,
          title: this.markdownPage.title,
          page: this.markdown
        }
      }).then((response) => {
        if (response.status === 200) {
          this.editDialog = false
        }
      })
    },
    getButtonTitle (buttonName) {
      return this.$tp(`components.management.dialogs.edit.easyMde.buttons.${buttonName}`)
    }
  },
  computed: {
    ...mapGetters('markdownPages', ['markdownPage', 'loading']),
    pageContent () {
      if (this.markdownPage === null) {
        return ''
      }
      return this.parseMarkdown(this.markdownPage.page)
    },
    editButtonText () {
      return this.$tp('components.management.buttons.edit')
    },
    editDialogTitle () {
      return this.$tp('components.management.dialogs.edit.title')
    },
    editDialogCloseButton () {
      return this.$tp('components.management.dialogs.edit.buttons.close')
    },
    editDialogUpdateButton () {
      return this.$tp('components.management.dialogs.edit.buttons.update')
    },
    showEditor () {
      return this.easyMde !== null
    }
  },
  watch: {
    markdownPage (newValue) {
      if (newValue !== null) {
        this.markdown = newValue.page
      }
    },
    editDialog (newValue) {
      if (newValue && this.easyMde === null) {
        setTimeout(() => {
          this.easyMde = new EasyMDE({
            element: document.getElementById('mde'),
            hideIcons: ['image', 'guide'],
            previewRender: (text) => {
              return this.parseMarkdown(text)
            },
            toolbar: [
              {
                name: 'bold',
                action: EasyMDE.toggleBold,
                className: 'text-h5',
                icon: this.$iconSvgs.mdiFormatBold,
                title: this.getButtonTitle('bold')
              },
              {
                name: 'italic',
                action: EasyMDE.toggleItalic,
                className: 'text-h5',
                icon: this.$iconSvgs.mdiFormatItalic,
                title: this.getButtonTitle('italic')
              },
              {
                name: 'heading-1',
                action: EasyMDE.toggleHeading1,
                className: 'text-h5',
                icon: this.$iconSvgs.mdiFormatHeader1,
                title: this.getButtonTitle('heading1')
              },
              {
                name: 'heading-2',
                action: EasyMDE.toggleHeading2,
                className: 'text-h5',
                icon: this.$iconSvgs.mdiFormatHeader2,
                title: this.getButtonTitle('heading2')
              },
              {
                name: 'heading-3',
                action: EasyMDE.toggleHeading3,
                className: 'text-h5',
                icon: this.$iconSvgs.mdiFormatHeader3,
                title: this.getButtonTitle('heading3')
              },
              '|',
              {
                name: 'quote',
                action: EasyMDE.toggleBlockquote,
                className: 'text-h5',
                icon: this.$iconSvgs.mdiFormatQuoteClose,
                title: this.getButtonTitle('quote')
              },
              {
                name: 'unordered-list',
                action: EasyMDE.toggleUnorderedList,
                className: 'text-h5',
                icon: this.$iconSvgs.mdiFormatListBulleted,
                title: this.getButtonTitle('unorderedList')
              },
              {
                name: 'ordered-list',
                action: EasyMDE.toggleOrderedList,
                className: 'text-h5',
                icon: this.$iconSvgs.mdiFormatListNumbered,
                title: this.getButtonTitle('orderedList')
              },
              '|',
              {
                name: 'link',
                action: EasyMDE.drawLink,
                className: 'text-h5',
                icon: this.$iconSvgs.mdiLink,
                title: this.getButtonTitle('link')
              },
              '|',
              {
                name: 'preview',
                action: EasyMDE.togglePreview,
                className: 'text-h5 no-disable',
                icon: this.$iconSvgs.mdiEyeOutline,
                title: this.getButtonTitle('preview')
              },
              {
                name: 'side-by-side',
                action: EasyMDE.toggleSideBySide,
                className: 'text-h5 no-disable',
                icon: this.$iconSvgs.mdiContentCopy,
                title: this.getButtonTitle('sideBySide')
              },
              {
                name: 'fullscreen',
                action: EasyMDE.toggleFullScreen,
                className: 'text-h5 no-disable',
                icon: this.$iconSvgs.mdiFullscreen,
                title: this.getButtonTitle('fullScreen')
              }
            ],
            spellChecker: false,
            lineNumbers: true
          })
          this.easyMde.codemirror.on('change', () => {
            this.markdown = this.easyMde.value()
          })
        }, 250)
      }
    }
  }
}
</script>

<style scoped>
>>> h1,
>>> h2,
>>> h3,
>>> h4,
>>> h5,
>>> h6 {
  margin-bottom: 0.8em
}

>>> .editor-statusbar {
  display: none
}

>>> .editor-preview {
  padding: 2rem
}

>>> .editor-toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

>>> .editor-toolbar button.heading-1::after,
>>> .editor-toolbar button.heading-2::after,
>>> .editor-toolbar button.heading-3::after {
  content: ''
}

>>> .editor-toolbar button {
  padding-top: 3px;
  padding-left: 3px;
}

.version {
  color: #bdbdbd;
  padding: .5rem;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
